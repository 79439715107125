import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import axios from 'axios'


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

//Vue.prototype.$token = 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJlbWFpbCI6InVzZXIxQGV4YW1wbGUuY29tIiwibmJmIjoxNzA0OTgwODkyLCJleHAiOjE3MDU1ODU2OTJ9.zi5oIgv6HV6PLyMr_N-tnd_kxfX2rEgto-y29EeM88kYvfLbilcTC9zLlDC0DuZZdz8I_-sE74KaUuqZwkcwaA';

Vue.prototype.JWT_TOKEN_SECRET = 'mysupersecretkeymysupersecretkeymysupersecretkeymysupersecretkey'
axios.defaults.baseURL = 'https://api.ybmaluminium.com'
axios.defaults.headers.common.Authorization = `Bearer ${store.getters['rabia/getToken'] ?? ''}`

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.prototype.$swalDeleteAsk = function () {
  return new Promise((resolve) => {
    this.$swal({
      title: "Silmek istediğinize emin misiniz?",
      text: "Yapılan işlem geri alınamaz!",
      icon: "warning",
      showClass: {
        popup: "animateanimated animateshakeX",
      },
      showCancelButton: true,
      confirmButtonText: "Sil",
      cancelButtonText: "İptal",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
    }).then((result) => {
      resolve(result.value);
    });
  });
};
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
