import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        siteCompId: 1,
        pageTitle: 'Home',
        layout: 'full',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('@/views/About.vue'),
      meta: {
        siteCompId: 2,
        pageTitle: 'about',
        layout: 'full',
        breadcrumb: [
          {
            text: 'about',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sektorler',
      name: 'sektorler',
      component: () => import('@/views/Sektörler/sektorler.vue'),
      meta: {
        siteCompId: 3,
        pageTitle: 'sektorler',
        layout: 'full',
        breadcrumb: [
          {
            text: 'sektorler',
            active: true,
          },
        ],
      },
    },
    {
      path: '/urunler',
      name: 'urunler',
      component: () => import('@/views/Urunler/urunler.vue'),
      meta: {
        siteCompId: 4,
        pageTitle: 'urunler',
        layout: 'full',
        breadcrumb: [
          {
            text: 'urunler',
            active: true,
          },
        ],
      },
    },
    {
      path: '/uretim',
      name: 'uretim',
      component: () => import('@/views/Uretim/uretim.vue'),
      meta: {
        siteCompId: 5,
        pageTitle: 'uretim',
        layout: 'full',
        breadcrumb: [
          {
            text: 'uretim',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kalite',
      name: 'kalite',
      component: () => import('@/views/kalite/kalite.vue'),
      meta: {
        siteCompId: 6,
        pageTitle: 'kalite',
        layout: 'full',
        breadcrumb: [
          {
            text: 'kalite',
            active: true,
          },
        ],
      },
    },

    {
      path: '/iletisim',
      name: 'iletisim',
      component: () => import('@/views/Contact.vue'),
      meta: {
        siteCompId: 7,
        pageTitle: 'iletisim',
        layout: 'full',
        breadcrumb: [
          {
            text: 'iletisim',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kvkk',
      name: 'kvkk',
      component: () => import('@/views/kvkk/kvkk.vue'),
      meta: {
        siteCompId: 8,
        pageTitle: 'iletisim',
        layout: 'full',
        breadcrumb: [
          {
            text: 'iletisim',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    // Admin Route
    {
      path: '/home-edit',
      name: 'home-edit',
      component: () => import('@/views/Admin/Edit/home/home-edit.vue'),
      meta: { 
      siteCompId: 1,
      requiresAdmin: true
        } 
    },
    {
      path: '/about-edit',
      name: 'about-edit',
      component: () => import('@/views/Admin/Edit/about/about-edit.vue'),
      meta: { 
      siteCompId: "2", 
      requiresAdmin: true 
    } 

    }, 
    {
      path: '/product-edit',
      name: 'product-edit',
      component: () => import('@/views/Admin/Edit/product/product-edit.vue'),
      meta: { 
      siteCompId: "3", 
      requiresAdmin: true 
    } 

    },
    {
      path: '/sectors-edit',
      name: 'sectors-edit',
      component: () => import('@/views/Admin/Edit/sectors/sectors-edit.vue'),
      meta: { 
      siteCompId: "4", 
      requiresAdmin: true
     } 

    },
    {
      path: '/production-edit',
      name: 'production-edit',
      component: () => import('@/views/Admin/Edit/production/production-edit.vue'),
      meta: { 
      siteCompId: "5",
      requiresAdmin: true
       } 

    },
    {
      path: '/quality-edit',
      name: 'quality-edit',
      component: () => import('@/views/Admin/Edit/quality/quality-edit.vue'),
      meta: { 
      siteCompId: "6", 
       requiresAdmin: true 
    } 

    },
    {
      path: '/contact-edit',
      name: 'contact-edit',
      component: () => import('@/views/Admin/Edit/contact/contact.vue'),
      meta: { 
      siteCompId: "7",
       requiresAdmin: true,
      } 
      
    },
    {
      path: '/user-edit',
      name: 'user-edit',
      component: () => import('@/views/Admin/Edit/users/user-edit.vue'),
      meta: { 
      siteCompId: "8",
      requiresAdmin: true,
      } 
      
    },
    {
      path: '/kvkk-edit',
      name: 'kvkk-edit',
      component: () => import('@/views/Admin/Edit/kvkk/kvkk-edit.vue'),
      meta: { 
      siteCompId: "9",
      requiresAdmin: true,
      } 
      
    },

    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Admin/Profile/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Profile',
        requiresAdmin: true ,
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
});

router.beforeEach((to, from, next) => {
  next();
  /*
  const store = router.app.$options.store
  const token = store.getters['rabia/getToken']
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else {
    jwt.verify(token, Vue.prototype.JWT_TOKEN_SECRET, function (err, decoded) {
      if (decoded) {
        if (to.meta.roles && to.meta.roles.length > 0) { // Check if roles are defined and not empty
          if (store.getters['rabia/getCanHaveRole'](to.meta.roles)) {
            next()
          } else {
            next({ name: 'home' })
          }
        } else { // If no roles are defined for the route, allow access
          next()
        }
      }
      if (err) {
        localStorage.removeItem('token')
        if (to.name !== 'login') {
          next({ name: 'login' })
        } else {
          next()
        }
      }
    })
  }
  */
});



export default router

