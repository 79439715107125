import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    token:localStorage.getItem("token"),
    username:"",
    role:""
  },
  getters: {
    getUsername(state) {
        return state.username;
    },
    getToken(state) {
      return state.token;
    },
    getCanHaveRole: (state) => (val = []) => {
      try {
          const decoded = jwt.verify(state.jwtToken, Vue.prototype.JWT_TOKEN_SECRET)
          return Array.isArray(decoded.roles) ?
              decoded.roles.some(item => val.includes(item)) :
              val.includes(decoded.roles)
      } catch (err) {
          localStorage.removeItem('token')
          return null
      }
  },
  },
  mutations: {
    changeJwtToken(state, val) {
      state.token = val
    },
  },
  actions: {
    saveToken({commit}, val) {
      commit('changeJwtToken', val)
    },
  },
}
